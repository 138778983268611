import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import MaterialTabs from '@mui/material/Tabs'

const StyledMaterialTabs = styled(MaterialTabs)`
  & .flexContainer {
    display: flex;

    .indicator {
      background-color: ${(props) => props.indicatorColor || props.theme.default.color.stol};
      margin-top: 1px;
      width: 100%;
      position: absolute;
      bottom: 0;
      height: 2px;
    }
  }

  & .indicator {
    background-color: ${(props) => props.indicatorColor || props.theme.default.color.stol};
  }
`
const Tabs = ({ children, indicatorColor, ...props }) => (
  <StyledMaterialTabs
    variant="scrollable"
    indicatorColor={indicatorColor}
    scrollButtons="false"
    {...props}
    classes={{ indicator: 'indicator', flexContainer: 'flexContainer' }}
  >
    {children}
  </StyledMaterialTabs>
)

Tabs.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
}

export default Tabs
